<template>
  <div class="bg" :key="htmlIndex">
    <div class="helpCenter">
      <img src="../assets/lang_switch.png" alt="" />
      {{showLang('logon.switch.language')}}
      <Select v-model="langActive" style="width:100px;margin-left: 5px;">
        <Option v-for="item in langList" :value="item.code" :key="item.code">{{ item.name }}</Option>
      </Select>
    </div>
    <div class="conBox">
      <img src="../assets/logo.png" class="logo" alt="" />
      <template v-if="langActive == 'zh'">
        <p class="title1">{{domains.title}}</p>
        <p class="title2">URBAN LIGHTING INTELLIGENT MONITORING SYSTEM</p>
      </template>
      <template v-else>
        <p class="title1">URBAN LIGHTING INTELLIGENT MONITORING SYSTEM</p>
        <p class="title2">{{domains.title}}</p>
      </template>
      <!-- <p class="title3">让照明变得越来越智慧</p> -->
      <div class="loginBox">
        <div class="login-tabs" v-if="!domains.simple">
          <div class="login-tab" :class="[isLoginWay==0?'tab-active':'']" @click="isLoginWay=0">{{showLang('logon.Account.login')}}</div>
          <div class="login-tab" :class="[isLoginWay==1?'tab-active':'']" @click="isLoginWay=1">{{showLang('logon.SMS.login')}}</div>
          <div class="login-tab" :class="[isLoginWay==2?'tab-active':'']" @click="isLoginWay=2">{{showLang('logon.Scan.Code.Login')}}</div>
        </div>
        <div class="input" v-if="isLoginWay==0">
          <div class="inputBox">
            <Input :placeholder="showLang('logon.enter.Account')" v-model="form.name" class="inputBox-index" @on-keypress="keypress($event, 'name')">
            <template #prefix>
              <img src="../assets/user.png" alt="" />
            </template>
            </Input>
            <div class="isPswd" v-if="!domains.simple">
              <Checkbox v-model="isPswd">{{showLang('logon.remember.password')}}</Checkbox>
            </div>
          </div>
          <div class="inputBox ml20">
            <Input :placeholder="showLang('logon.enter.password')" ref="pswd" type="password" v-model="form.pswd" class="inputBox-index" @on-keypress="keypress($event, 'pswd')">
            <template #prefix>
              <img src="../assets/pswd.png" alt="" />
              <img src="../assets/eye.png" alt="" style="left: 260px;top: 15px;" @click="eyeBut()" />
            </template>
            </Input>
          </div>
          <div class="inputBox ml20 mr10">
            <Input :placeholder="showLang('logon.Code.input')" v-model="form.captchaCode" class="inputBox-index" @on-keypress="keypress($event, 'captcha')">
            <template #prefix>
              <img src="../assets/captcha.png" alt="" />
              <!-- <img src="../assets/eye.png" alt="" style="left: 300px;top: 15px;" @click="eyeBut()" /> -->
            </template>
            </Input>
            <img :src="`//${this.domains.trans}/common/auth/ShowCaptcha?id=${captchaData}`" alt="" @click="throttle()" :key="captchaIndex" style="left: 170px;width: 130px; height: 54px;top: 0;">
          </div>
          <!-- <div class="inputBox">
            <img src="../assets/user.png" alt="" />
            <input type="text" v-model="form.name" class="text-cursor" :placeholder="showLang('logon.enter.Account')" maxlength="20"
              @keypress="keypress($event, 'name')" />
          
          </div>
          <div class="inputBox">
            <img src="../assets/pswd.png" alt="" />
            <input ref="pswd" type="password" v-model="form.pswd" maxlength="20" :placeholder="showLang('logon.enter.password')"
              @keypress="keypress($event, 'pswd')" />
            <img src="../assets/eye.png" alt="" class="eye" />
          </div> -->
          <input type="button" :value="showLang('logon.log.on')" class="signIn " @click="validate" />
          <!-- type=password  修改类型查看密码 -->
          <ModalModifyPswd v-model="showModifyModal" :user="form.name" />
        </div>
        <div class="input" v-if="isLoginWay==1">
          <div class="inputBox">
            <img src="../assets/phone.png" alt="" style="top:25px;left:20px" />
            <input type="text" v-model="formPhone.phone" :placeholder="showLang('logon.enter.phone')" maxlength="20" @keypress="keypress($event, 'phone')" />
          </div>
          <div class="inputBox">
            <img src="../assets/pswd.png" alt="" style="top:25px;left:20px" />
            <input type="text" v-model="formPhone.code" maxlength="4" :placeholder="showLang('logon.Code')" @keypress="keypress($event, 'code')" :key="codeIndex" />
            <div class="inputCode" :class="[isDisabled?'isDisabled':'']" @click="getCode" :key="codeCopywriting">
              {{codeCopywriting}}</div>
          </div>
          <input type="button" :value="showLang('logon.log.on')" class="signIn" @click="loginSms" />
        </div>
      </div>
      <div class="appImg" v-if="domains.appUrl &&  domains.appUrl !='' && isLoginWay !=2  && !domains.simple">
        <img :src="img.app.appLogo" alt="">
        <p>{{showLang('logon.download.code.app')}}</p>
      </div>
      <div class="qrCodeBox" v-if="isLoginWay==2 && qrCode!='' ">
        <img :src="`//${domains.trans}/common/auth/ShowQrCode?id=${qrCode}`" alt="" class="qrCodeBox-img">
        <div class="showQrCode" @click="showQrCode" v-show="showQrCodeTime">
          <img :src="img.light.factory.power.devOps2.devOps10" alt="">
          <p>二维码过期,点图片重新获取</p>
          <!-- img.pole.icons.led.restart -->
          <!-- img.light.factory.power.devOps2.devOps10 -->
        </div>
        <p class="qrCodeBox-p">{{showLang('logon.enter.app.logon')}}</p>
        <Button type="primary" @click="isShowAppLogo = true"><img :src="img.app.download" alt=""> {{showLang('logon.download.app')}}</Button>
      </div>
    </div>
    <Modal v-model="isShowAppLogo" :title="showLang('logon.download.install.app')" width="400">
      <img :src="img.app.appLogo" alt="" style="width:200px;height: 200px;margin-left: 85px;">
    </Modal>
    <div class="icon-bottom">
      <div class="iconList">
        <div class="icon">
          <img src="../assets/lighting.png" alt="" />
          <p>{{showLang('com.head.lighting')}}</p>
        </div>
        <div class="icon">
          <img src="../assets/devOps.png" alt="" />
          <p>{{showLang('com.head.devOps')}}</p>
        </div>
        <div class="icon">
          <img src="../assets/assets.png" alt="" />
          <p>{{showLang('com.head.property')}}</p>
        </div>
        <div class="icon">
          <img src="../assets/analysis.png" alt="" />
          <p>{{showLang('com.head.data')}}</p>
        </div>
        <div class="icon">
          <img src="../assets/police.png" alt="" />
          <p>{{showLang('com.stat.alarm')}}</p>
        </div>
      </div>
    </div>
    <div class="icp-bottom" v-if="domains.needBeian">
      <a href="https://beian.miit.gov.cn" target="_blank" style="margin-right: 5px">粤ICP备2021067683号-1</a>
      <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602005852#" target="_blank">粤公安备44030602005852号</a>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mapState, mapGetters } from "vuex";
import { localData } from '@/plugins/cookie'
// import ModalModifyPswd from '@/views/common/modals/ModalModifyPswd'
import ModalModifyPswd from '@/views/news/header/user/ModalModifyPswd'
Vue.prototype.$localData = { localData }
export default {
  name: "Home",
  components: {
    ModalModifyPswd,
  },
  computed: {
    ...mapState("auth", ["token", "user", "appType", "customerId", "appTypes", 'ops', 'isNewMode']),
    ...mapGetters('auth', ['funCodes', 'showLang']),
  },
  data: () => ({
    beian: require("../assets/beian.png"),
    valid: false,
    agree: true,
    hover: true,
    redirect: null,
    langActive: 'zh',
    langList: [
      { name: '中文', code: 'zh' },
      { name: 'English', code: 'en' },
    ],
    form: {
      name: "",
      pswd: "",
      captchaId: '',
      captchaCode: '',
    },
    codeCopywriting: '获取验证码',
    formPhone: {
      phone: "",
      code: "",
    },
    isShowAppLogo: false,
    time: 60,
    timer: null,
    isDisabled: false,
    isPswd: false,
    showModifyModal: false,
    isLoginWay: 0,
    codeIndex: 0,
    htmlIndex: 0,
    qrCode: '',
    captchaData: '',
    captchaIndex: 0,
    canRun: true,
    rule: {
      name: [
        { required: true, message: "用户名不能为空", trigger: "blur" },
        {
          type: "string",
          min: 2,
          max: 10,
          message: "用户名长度必须2 - 10个字符",
          trigger: "blur",
        },
      ],
      pswd: [
        { required: true, message: "密码不能为空", trigger: "blur" },
        {
          type: "string",
          min: 5,
          max: 10,
          message: "密码长度必须6 - 10个字符",
          trigger: "blur",
        },
      ],
    },
    showQrCodeTime: false
  }),
  watch: {
    'formPhone.phone'() {
      this.formPhone.code = '';
      this.codeIndex++;
    },
    langActive() {
      localStorage.setItem("lighting_system_lang", this.langActive)
      this.langAjax();
      this.codeCopywriting = this.showLang('logon.obtain.code')
    },
    isLoginWay() {
      if (this.isLoginWay == 2) {
        this.getQrCode();
      }
    }
  },
  mounted: function () {
    localStorage.setItem('token', null);
    localStorage.setItem('user', null);
    let account = this.getCookie("account");
    let password = this.getCookie("password");
    if (account) {
      this.form.name = account
      this.form.pswd = password
      this.isPswd = true
    }
    let lang = localStorage.getItem("lighting_system_lang")
    this.htmlIndex++
    if (lang != null) {
      this.langActive = lang;
    } else {
      localStorage.setItem("lighting_system_lang", 'zh')
      this.langActive = 'zh';
    }
    this.langAjax();
    this.getCaptcha();
    this.codeCopywriting = this.showLang('logon.obtain.code')
    window.eventBus.$on('qrcodeDataComing', params => {
      if (params.code == this.qrCode && params.success) {
        this.$store.commit('auth/loginNew', params.token);
        setTimeout(() => {
          if (this.isNewMode) {
            this.$router.push('/main');
          } else if (this.appType == 'road') {
            this.$router.push('/index/light/road/home');
          } else if (this.appType == 'factory') {
            this.$router.push('/index/light/factory/home');
          } else if (this.appType == 'pole') {
            this.$router.push('/index/pole/home');
          } else if (this.appType == 'light') {
            this.$router.push('/index/light/single');
          } else {
            this.$router.push('/main');
          }
          // let scenes = this.appTypes.filter(p => p.code == this.appType);
          // if (scenes.length == 0) {
          //   this.$Message.warning(this.showLang('logon.authority.no.administrator'));
          //   return;
          // }
          // this.$router.push(scenes[0].url);
        }, 250);
      }
    })
  },
  destroyed: function () {
    window.eventBus.$off('qrcodeDataComing');
  },
  methods: {
    showQrCode() {
      this.getQrCode();
      this.showQrCodeTime = false;
    },
    getQrCode() {
      this.showQrCodeTime = false;
      this.$axios.post(`common/auth/GetQrCode`, {}).then(res => {
        if (res.code == 0) {
          this.qrCode = res.data;
          this.$store.commit('cmd/enableQrCode', res.data);
          setTimeout(() => {
            this.showQrCodeTime = true;
          }, 60000);
        }
      });
    },
    getCaptcha() {
      this.$axios.post(`common/auth/GetCaptcha`, {}).then(res => {
        if (res.code == 0) {
          this.captchaData = res.data;
          this.form.captchaId = res.data;
          this.captchaIndex++;
        }
      });
    },
    throttle() {
      if (this.canRun == null) {
        this.getCaptcha();
        this.canRun = Date.now();
      } else {
        const currentTime = Date.now();
        if (currentTime - this.canRun >= 1000) {
          this.getCaptcha();
          this.canRun = currentTime;
        }
      }
    },
    getCode() {
      if (!this.isPhone()) {
        this.$Message.warning(this.showLang('logon.enter.ok.phone'));
        return false
      }
      if (this.isDisabled) { return false }
      Vue.$axios.post(`common/auth/GetSmsCode`, { mobile: this.formPhone.phone }).then((res) => {
        if (res.code == 0) {
          this.isDisabled = true;
          this.timer = setInterval(() => {
            if (this.time == 0) {
              clearInterval(this.timer);
              this.isDisabled = false;
              this.codeCopywriting = this.showLang('logon.obtain.code');
              this.time = 60;
            } else {
              this.codeCopywriting = this.time + 's';
              this.time--;
            }
          }, 1000);
        }
      })

    },
    langAjax() {
      this.$store.commit('auth/setLang', this.langActive);
    },
    eyeBut() {
      this.$refs.pswd.type = "text";
      setTimeout(() => {
        if (this.$refs.pswd) {
          this.$refs.pswd.type = "password";
        }
      }, 3000);
    },
    keypress: async function (e, n) {
      if (e.keyCode == 13) {
        switch (n) {
          case 'name':
            if (this.form.name.length == 0 || this.form.name.length > 20) {
              this.$Message.warning(this.showLang('logon.tell.name20'));
              return;
            } else {
              this.$refs.pswd.focus({ cursor: "all" });
            }
            break;
          case 'pswd':
            if (this.form.pswd.length == 0 || this.form.pswd.length > 20) {
              this.$Message.warning(this.showLang('logon.tell.name20'));
              return;
            } else {
              this.validate();
            }
            break;
          case 'captcha':
            if (!(this.form.captchaCode.length == 4)) {
              this.$Message.warning(this.showLang('logon.Code.error'));
              return;
            } else {
              this.validate();
            }
            break;
          case 'phone':
            if (this.isPhone()) {
              this.$Message.warning(this.showLang('logon.phone.Incorrect'));
              return;
            }
            break;
          case 'code':
            if (this.formPhone.code.length == 0 || this.formPhone.code.length > 4) {
              this.$Message.warning(this.showLang('logon.phone.code.Incorrect'));
              return;
            } else {
              this.loginSms();
            }
            break;
        }
      }
    },
    isPhone() {
      let pattern = /^1[3456789]\d{9}$/;
      return pattern.test(this.formPhone.phone);
    },
    loginSms: async function () {
      if (!this.isPhone) {
        this.$Message.warning(this.showLang('logon.phone.Incorrect'));
        return;
      }
      if (this.formPhone.code.length == 0 || this.formPhone.code.length > 4) {
        this.$Message.warning(this.showLang('logon.phone.code.Incorrect'));
        return;
      }
      this.$store.commit("auth/setAppType", "");
      let ajaxData = {
        code: this.formPhone.code,
        mobile: this.formPhone.phone
      }
      this.$store.dispatch("auth/loginSms", ajaxData).then((res) => {
        this.isDisabled = false;
        this.time = 60;
        if (res.code == 0) {
          if (this.isNewMode) {
            this.$router.push('/main');
          } else if (this.appType == 'road') {
            this.$router.push('/index/light/road/home');
          } else if (this.appType == 'factory') {
            this.$router.push('/index/light/factory/home');
          } else if (this.appType == 'pole') {
            this.$router.push('/index/pole/home');
          } else if (this.appType == 'light') {
            this.$router.push('/index/light/single');
          } else {
            this.$router.push('/main');
          }
          return;
        } else {
          this.$Message.warning(this.showLang('logon.tell.name20'));
          return;
        }
      });
    },
    validate: async function () {
      if (this.form.name.length == 0 || this.form.name.length > 20) {
        this.$Message.warning(this.showLang('logon.tell.name20'));
        return;
      }
      if (this.form.pswd.length == 0 || this.form.pswd.length > 20) {
        this.$Message.warning(this.showLang('logon.tell.pswd20'));
        return;
      }
      if (this.form.captchaCode.length == 0 && this.isLoginWay == 0) {
        this.$Message.warning(this.showLang('logon.Code.error'));
        return;
      }
      this.$store.commit("auth/setAppType", "");
      this.$store.dispatch("auth/login", this.form).then((res) => {
        if (res.code == 0) {
          this.setUserInfo();
          if (res.data.modify) {
            let that = this;
            this.$Modal.warning({
              title: this.showLang('com.pswd.modify'),
              content: this.showLang('logon.pwds.no.require'),
              onOk: function () {
                that.showModifyModal = true
              }
            });
            return;
          }
          // console.log('login ok', this.isNewMode, this.appType)
          if (this.isNewMode) {
            this.$router.push('/main');
          } else if (this.appType == 'road') {
            this.$router.push('/index/light/road/home');
          } else if (this.appType == 'factory') {
            this.$router.push('/index/light/factory/home');
          } else if (this.appType == 'pole') {
            this.$router.push('/index/pole/home');
          } else if (this.appType == 'light') {
            this.$router.push('/index/light/single');
          } else {
            this.$router.push('/main');
          }
        } else {
          this.getCaptcha();
        }
      });
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setUserInfo: function () {
      if (this.isPswd) {
        this.setCookie("account", this.form.name)
        this.setCookie("password", this.form.pswd)
      } else {
        this.setCookie("account", "")
        this.setCookie("password", "")
      }
    },
    // 获取cookie
    getCookie: function (key) {
      if (document.cookie.length > 0) {
        let start = document.cookie.indexOf(key + '=')
        if (start !== -1) {
          start = start + key.length + 1
          let end = document.cookie.indexOf(';', start)
          if (end === -1) end = document.cookie.length
          return unescape(document.cookie.substring(start, end))
        }
      }
      return ''
    },
    // 保存cookie
    setCookie: function (cName, value, expiredays) {
      let exdate = new Date()
      exdate.setDate(exdate.getDate() + expiredays)
      document.cookie = cName + '=' + decodeURIComponent(value) +
        ((expiredays == null) ? '' : ';expires=' + exdate.toGMTString())
    },
  },
};
</script>
<style scoped>
.bg {
  background-image: url(../assets/body.png);
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-position: center 0;
}
.helpCenter {
  font-size: 16px;
  font-weight: 400;
  color: #f2f4f9;
  position: fixed;
  right: 80px;
  top: 50px;
  display: inline-block;
  z-index: 50000;
}
.helpCenter img {
  vertical-align: middle;
  margin-right: 5px;
}
.helpCenter i {
  vertical-align: middle;
  font-style: inherit;
  text-decoration: underline;
}
.logo {
  width: 105px;
  height: 104px;
}
.conBox {
  margin: 0 auto;
  text-align: center;
  position: relative;
  top: 50%;
  margin-top: -350px;
}
.title1 {
  font-size: 50px;
  font-family: Microsoft YaHei;
  color: #ffffff;
  margin-top: 48px;
}
.title2 {
  font-size: 18px;
  font-family: Arial;
  color: #ffffff;
}
.appImg {
  width: 350px;
  height: 110px;
  margin: 60px auto 0;
}
.appImg img {
  width: 110px;
  height: 110px;
  position: relative;
  z-index: 20;
}
.appImg p {
  text-align: center;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
}
.icon-bottom {
  position: fixed;
  width: 100%;
  bottom: 90px;
}
.iconList {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.iconList .icon {
  margin: 0 35px;
}
.iconList .icon p {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin-top: 18px;
}
.loginBox {
  width: 1140px;
  margin: 30px auto 0;
  position: relative;
}
.input {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input .inputBox {
  position: relative;
}

.input .inputBox .inputCode {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 155px;
  height: 54px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  border-radius: 0 5px 5px 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid #009dff;
  background-color: #009dff;
  line-height: 54px;
}
.input .inputBox .inputCode.isDisabled {
  opacity: 0.5;
}
.input .inputBox .isPswd {
  position: absolute;
  left: 2px;
  top: 77px;
  font-size: 14px;
  color: #b2d3ff;
}
.input .signIn {
  width: 149px;
  height: 54px;
  background: #009dff !important;
  border-radius: 6px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  text-indent: 0;
}
.icp-bottom {
  position: absolute;
  bottom: 30px;
  text-align: center;
  height: 25px;
  width: 100%;
}
.icp-bottom a {
  color: #aab0be;
  font-size: 14px;
}
.input input {
  width: 343px;
  height: 54px;
  margin: 10px;
  display: flex;
  color: #aab0be;
  font-size: 18px;
  justify-content: center;
  align-items: center;
  background-color: #ffffff !important;
  position: relative;
  border-radius: 6px;
  border: 0;
  outline-style: none;
  align-self: stretch;
  line-height: 54px;
  text-indent: 54px;
}
.input input::-webkit-input-placeholder {
  /* WebKit browsers */
  font-size: 16px;
  background-color: #ffffff !important;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
.input input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  font-size: 16px;
  background-color: #ffffff !important;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
.input input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  font-size: 16px;
  background-color: #ffffff !important;
  font-weight: 400;
  position: relative;
  color: #aab0be;
  line-height: 54px;
  text-indent: 54px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-transition-delay: 111111s;
  -webkit-transition: color 11111s ease-out, background-color 111111s ease-out;
}
.login-tabs {
  height: 48px;
  flex: none;
  display: flex;
  margin-left: 10px;
  margin-bottom: 5px;
}
/* login-tab" :class="[isLoginWay?'tab-active */
.login-tab {
  padding: 2px 10px;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 48px;
  opacity: 0.6;
}
.login-tab.tab-active {
  color: #ffffff;
  opacity: 1;
}
.tab-active::after {
  content: "";
  width: calc(75%);
  height: 2px;
  display: block;
  margin: -5px auto 0;
  border-bottom: 3px solid #009dff;
}
.qrCodeBox {
  text-align: center;
  position: relative;
}
.qrCodeBox-img {
  width: 180px;
  height: 180px;
  display: inline-block;
}
.qrCodeBox-p {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffffff;
  margin: 10px 0;
}
.inputBox-index {
  width: 300px;
  height: 54px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff !important;
  position: relative;
  border-radius: 6px;
  border: 0;
  outline-style: none;
  align-self: stretch;
  text-indent: 54px;
}
>>> .ivu-input {
  height: 54px;
  text-indent: 16px;
  color: #aab0be;
  font-size: 18px;
  caret-color: #009dff;
  /* caret-width: 5px; */
}
.input .inputBox img {
  position: absolute;
  left: 15px;
  top: 15px;
  z-index: 50;
}
.showQrCode {
  width: 180px;
  height: 180px;
  background-color: rgba(0, 157, 255, 0.5);
  position: absolute;
  left: 50%;
  margin-left: -90px;
  margin-top: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-align: center;
}
.showQrCode p {
  margin-top: 30px;
  color: #f00;
}
.showQrCode img {
  width: 50px;
  height: 50px;
  margin: 0 auto 20px;
}
</style>