// "use strict";

import Vue from 'vue';
import axios from "axios";
import router from '../router';
import CryptoJS from 'crypto-js';
import {
  Modal
} from 'view-design';

const domains = {
  imgs: window.domains.imgs,
  trans: window.domains.trans,
  monitor: window.domains.monitor,
  needBeian: window.domains.needBeian,
  appUrl: window.domains.appUrl,
  front: window.domains.front,
  simple: window.domains.simple,
  title: window.domains.title,
  version: window.domains.version,
  host: window.domains.host,
}
const needEncrypt = window.domains.needEncrypt;
const needCheckIp = !window.domains.front;
let host = window.domains.trans,
  protocol = "http:",
  sub = ''; //开发环境
if (window.domains.host !== "config") { //生产环境
  host = window.location.host;
  protocol = window.location.protocol;
  sub = 'api/center/';
}
let config = {
  baseURL: `${protocol}//${host}/${sub}`,
  timeout: 60 * 1000, // Timeout
  // withCredentials: false, // Check cross-site Access-Control
  options: null,
};

domains.baseURL = config.baseURL;
// console.log('set domain url', domains)
function encodeBase64(str){
  return new Buffer(str).toString('base64');
}
function decodeBase64(str){
  return new Buffer(str, 'base64').toString();
}
function xssPrevent(str){
  return str.replace(/</g,'&lt;').replace(/>/g,'&gt;')
}
function encryptAES(message, key){
  let srcs = CryptoJS.enc.Utf8.parse(message);
  let keys = CryptoJS.enc.Utf8.parse(key);
  let encrypted = CryptoJS.AES.encrypt(srcs, keys, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}
function decryptAES(message, key){
  let keys = CryptoJS.enc.Utf8.parse(key);
  let decrypted = CryptoJS.AES.decrypt(message, keys, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypted).toString();
}
const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
function generalKey(){
  const length = 32;
  let result = '';
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters[randomIndex];
  }
  return result;
}
// let src = 'xxxxxx', key = '1234567890123456123456789012345g';
// let result1 = encryptAES(src, key);
// let result2 = decryptAES(result1, key);
// console.log('test crypt', result1, result2);
// for(let i = 0; i < 30; i++){
//   console.log('test crypt ' + i, generalKey());
// }

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function (config) {
    const custid = localStorage.getItem('custid');
    const token = localStorage.getItem('token');
    const apptype = localStorage.getItem('apptype');
    const lang = localStorage.getItem('lighting_system_lang')
    if (token) {
      config.headers.common['auth'] = token;
      config.headers.common['custid'] = custid;
      config.headers.common['apptype'] = apptype;
    }
    config.headers.common['lang'] = lang == null ? 'zh_cn' : lang=='zh'?'zh_cn':'en_us';
    // console.log('req content', config)
    if(config.myType && config.myType == 'file')return config;
    if(config.data){
      let temp = xssPrevent(JSON.stringify(config.data));
      let encrypted;
      if(needEncrypt){
        let key = generalKey();
        // console.log('req head', key);
        config.headers.common['appcu'] = key;
        config.headers.common['appth'] = generalKey();
        config.headers.common['appwd'] = generalKey();
        config.headers.common['appea'] = generalKey();
        config.headers.common['appiv'] = generalKey();
        encrypted = encryptAES(temp, key);
      }else{
        encrypted = encodeBase64(temp);//type:'base64',
      } 
      let pkg = {content: encrypted};//type:'base64', 
      if(process.env.NODE_ENV !== "production"){
        pkg.raw = config.data;
      }
      config.data = pkg;
    }
    if(needCheckIp){
      config.headers.common['ipchk'] = generalKey();
    }
    return config;
  },
  function (error) {
    return Promise.resolve({
      code: -9999,
      data: `请求出错：${error.message}`
    });
  }
);

let timer = null;
let debounceSessionTimeout = function (title, content, needLogin) {
  // debugger;
  clearTimeout(timer);
  timer = setTimeout(() => {
    if (needLogin) {
      localStorage.removeItem('token');
      localStorage.removeItem('ops');
      localStorage.removeItem('user');
    }
    Modal.error({
      title: title,
      content: content,
      onOk: function () {
        if (needLogin) {
          // let version = window.domains.version; //localStorage.getItem('version');
          let url = '/login';
          // if(version == 'new'){
          //   url = '/loginNew';
          // }
          setTimeout(() => {
            router.replace({
              path: url,
            });
          }, 100);
        }
      }
    });
  }, 400); //因为某些提示消息是由模态窗口发起的，所以这里需要延时一段时间，防止发起的模态窗口被关闭，造成界面一闪而过的BUG；
}

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    if (response.status !== 200) {
      return Promise.resolve({
        code: -9998,
        data: `请求失败：http status code: ${response.status}`
      });
    } else {
      // console.log('axios get res data', response)
      if (response.headers['content-type'].indexOf('application/json') != -1) {
        if (response.data.code == 990008) {
          debounceSessionTimeout("系统提示", "登录已过期，请重新登录", true);
          return Promise.resolve({
            code: 0,
            data: []
          })
        } else if (response.data.code != 0 && response.data.code != 990099) { //某些不需要在前端弹出提示窗口的错误消息，可让后端给出990099的错误代码
          if (response.data.type == 'application/json') {
            let reader = new FileReader();
            reader.readAsText(response.data);
            reader.addEventListener('loadend', function(){
              let json = JSON.parse(reader.result);
              debounceSessionTimeout("导出数据失败", json.data);
              return Promise.resolve(json);
            })
          }else{
            debounceSessionTimeout("系统提示", response.data.data);
            return Promise.resolve(response.data);
          }
        }
        // console.log('get res 1-----------------',  response.data.data)
        // let data = response.data.data;
        let jsonStr;
        if(needEncrypt){
          // console.log('res head', response.config.headers.appcu);
          jsonStr = decryptAES(response.data.data, response.config.headers.appcu);
        }else{
          jsonStr = decodeBase64(response.data.data);
        }
        let json = JSON.parse(xssPrevent(jsonStr));
        // console.log('get res 1', response.data, json)
        response.data.data = json;
        return Promise.resolve(response.data);
      } else {
        // console.log('get res 2', response.data)
        return Promise.resolve(response.data);
      }
    }
  },
  function (error) {
    return Promise.resolve({
      code: -9999,
      data: `请求出错：${error.message}`
    });
  }
);

Plugin.install = function (Vue, options) {
  config.options = options;
  Vue.axios = _axios;
  Vue.$axios = _axios;
  Vue.domains = domains;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    baseURL: {
      get() {
        return config.baseURL;
      }
    },
    clone: {
      get() {
        return function (obj) {
          let temp = null;
          if (obj instanceof Array) {
            temp = obj.concat();
          } else if (obj instanceof Function) {
            temp = obj;
          } else {
            temp = new Object();
            for (let item in obj) {
              let val = obj[item];
              temp[item] = typeof val == 'object' ? this.clone(val) : val;
            }
          }
          return temp;
        };
      }
    },
    domains: {
      get() {
        return domains;
      }
    },
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;